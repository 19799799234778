<template>
  <b-container>
    <b-row class="pt-5">
      <b-col lg="5" class="mb-4">
        <slider-thumbs :images="productDetails.images" />
      </b-col>
      <b-col lg="7">
        <div class="d-flex align-items-start justify-content-between">
          <h4 class="text-warning font-weight-bold">
            {{ productDetails.name }}
          </h4>
          <button class="wishlist_button" @click="addFav">
            <span>{{ $t('productDetails.wishlist') }}</span>
            <i class=" font-size-18" :class="fav?'fas fa-heart fav-color' : 'far fa-heart'"></i>
          </button>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-3">
          <div class="d-flex align-items-center justify-content-between gap_1">
            <router-link :to="{name: 'categories', params: {category: productDetails.category_id}}"><b-badge
              class="category_badge p-1 pl-4 pr-4 font-size-14 font-weight-light">{{
                productDetails.category.name }}</b-badge>
            </router-link>
            <router-link :to="{name: 'categories', params: {category: productDetails.category_id}, query: {
              sub : productDetails.subcategory_id}}">
              <b-badge class="category_badge p-1 pl-4 pr-4 font-size-14 font-weight-light">{{
                productDetails.subcategory.name }}</b-badge>
            </router-link>
            <img v-if="productDetails.offer > 0" :src="require('@/assets/images/curva/offer.png')" alt="offer">
          </div>
          <span class="arrival-tag" v-if="productDetails.availability==='coming soon'">{{ $t('productDetails.coming') }}</span>
        </div>
        <iq-card class="mt-3 px-3 py-2 iq-style3 d-flex justify-content-between align-items-center gap_1 flex-wrap">
          <span class="text-white  d-flex justify-content-between align-items-center gap_1 font-size-16">
            <span class="text-gray">{{ $t('productDetails.code') }}</span>
            {{ $route.params.id }}
          </span>
          <router-link v-if="productDetails.brand_id" :to="`/categories/all-categories?brand=${productDetails.brand_id}`">
            <b-badge class="category_badge px-3 py-2 d-flex gap_1 text-gray font-size-16 font-weight-light"
                   v-if="productDetails.brand.name">{{ $t('productDetails.brand') }}
            <span class="text-warning">{{ productDetails.brand.name }}</span></b-badge>
          </router-link>
          <router-link v-if="productDetails.club" :to="`/categories/all-categories?club=${productDetails.club_id}`">
            <b-badge class="category_badge px-3 py-2 d-flex gap_1 text-gray font-size-16 font-weight-light"
                   v-if="productDetails.club.name">{{ $t('productDetails.club') }}
            <span class="text-warning">{{ productDetails.club.name }}</span></b-badge>
          </router-link>
          <router-link v-if="productDetails.season_id" :to="`/categories/all-categories?season=${productDetails.season_id}`">
            <b-badge v-if="productDetails.season_id"
                     class="category_badge px-3 py-2 d-flex gap_1 text-gray font-size-16 font-weight-light">
              {{ $t('productDetails.season') }}
              <span class="text-warning"> {{ productDetails.season.name }} </span></b-badge>
          </router-link>
        </iq-card>
        <section>
          <p class="text-justify font-size-16" v-html="productDetails.description"></p>
          <div class="prop d-flex justify-content-start gap_2 align-items-center my-4">
            <label class="mb-0 text-gray font-size-14 text-nowrap">{{ $t('productDetails.size') }}
              <span class="text-danger">*</span></label>
            <div class="prop_details_size d-flex justify-content-start gap_1 flex-wrap">
              <b-badge class="category_badge p-2 px-3 cursor-pointer"
                       v-for="(sizeObj, key) in productDetails.sizes" :key="key"
                       :class="{'selected_size': orderDetails.size.id === sizeObj.size.id}"
                       @click.prevent="orderDetails.quantity=1;orderDetails.size = sizeObj.size;chooseColors=sizeObj.colors;orderDetails.color = null; newPrice = sizeObj.price">
                  <span>{{ sizeObj.size.name }}</span>
              </b-badge>
              <!-- {{ maxQuantity }} -->
            </div>
          </div>
          <div class="prop d-flex justify-content-start gap_2 align-items-center my-4"
          v-if="chooseColors.length">
           <label class="mb-0 text-gray font-size-14">{{ $t('productDetails.color') }}<span class="text-danger">*
           </span></label>
           <div class="d-flex justify-content-start gap_1">
             <div class="products_color_outside"
                   v-for="(color, key) in chooseColors"
                   :key="key"
                   @click="orderDetails.color = color.barcode;maxQuantity = color.quantity"
                   :class="{'selected_color': orderDetails.color === color.barcode}">
               <span class="products_color_inside d-inline-block"
                     :style="{'background-color': color.color.color }"></span>
             </div>
           </div>
         </div>

     <div class="prop d-flex justify-content-start gap_2 align-items-center my-4" v-if="orderDetails.color">
       <label class="mb-0 text-gray font-size-14">
         {{ $t('productDetails.quantity') }}<span class="text-danger">*</span></label>
       <div class="d-flex" >
         <b-button class="table-quantity-btn"
                   @click="orderDetails.quantity--" :disabled="orderDetails.quantity < 2">-</b-button>
         <input class="table-quantity-text" v-model="orderDetails.quantity" disabled/>
         <b-button class="table-quantity-btn" :disabled="maxQuantity == orderDetails.quantity || !orderDetails.color" @click="orderDetails.quantity++"
          >+</b-button>
       </div>
     </div>
     <div class="flex-md-row flex-column-reverse d-flex justify-content-between mt-5">
       <div class="d-flex border-offer" v-if="productDetails.offer > 0">
         <div class="m-0 bg_danger_light p-1 px-3">
           <img :src="require('@/assets/images/curva/offerLight.png')" alt="offers" />
         </div>
         <p
             class="m-0 bg_danger_dark offer-text p-1 px-3 font-weight-bold font-size-14 p-0 d-flex justify-content-center align-items-center">
           Get {{Math.floor(productDetails.offer)}}% Discount now!
         </p>
       </div>
       <p class="text-gray font-size-14 d-flex flex-grow-1 justify-content-end gap_1">
          <span v-if="!orderDetails.color" class="text-danger capitalize">{{ $t('please select size and color first to show availability') }}*</span>
          <template v-else>
            {{ $t('productDetails.availability') }}
            <span class="text-success"
              v-if="orderDetails.quantity > 0">
              {{ $t('productDetails.inStock') }}</span>
              <span class="text-danger" v-else>{{ $t('productDetails.out') }}</span>
          </template>
       </p>
     </div>
     <iq-card
         class="px-4 py-3 iq-style4 d-flex justify-content-between align-items-center flex-column  flex-md-row gap_1">
     <span class="text-white  d-flex justify-content-between align-items-center gap_1">
       <span class="text-gray">{{ $t('productDetails.cost') }}</span>
       <span v-if="productDetails.offer > 0">
         <span class="text-warning font-size-24 font-weight-bold mr-5"> {{ calcPrice }}
             <span class="text-gray font-size-14">{{$t('egp')}}</span></span>
         <span class="text-gray font-size-18 text-decoration-line-through mr-4">
             <span class="font-weight-bold">{{ newPrice || productDetails.price }}</span> {{$t('egp')}}</span>
       </span>
       <span v-else>
         <span class="text-warning font-size-24 font-weight-bold">
          {{ calcPrice }}
          <span class="text-gray font-size-14">{{$t('egp')}}</span></span>
       </span>
     </span>
       <div class="actions_buttons d-flex justify-content-between gap_1" v-if="maxQuantity > 0 && orderDetails.color">
         <b-button class="add_to_cart_button p-2 px-3" @click="addToCart(productDetails.id)">
         <span class="font-size-16">
           <img alt='cart' :src="require('@/assets/images/curva/fi-rr-shopping-cart-add.png')">
           {{ $t('productDetails.addCart') }}
         </span>
         </b-button>
         <router-link v-if="!ifUserLogin" :to="{
          name:'purchaseProduct',
           params: {
            id: productDetails.id,
            quantity: orderDetails.quantity,
            size: orderDetails.size,
            price: productDetails.price,
            barcode: this.orderDetails.color,
          }
          }">
           <b-button variant="warning" class="p-2 px-3">
             <span class="font-size-16">{{ $t('productDetails.buy') }}</span>
           </b-button>
         </router-link>
         <b-button v-else variant="warning" class="p-2 px-3" @click="addToCart(productDetails.id);
           $router.push({name :'cart'})">
           <span class="font-size-16">{{ $t('productDetails.buy') }}</span>
         </b-button>
       </div>
     </iq-card>

        </section>
      </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import ProductsServices from '@/modules/all-products/services/products.services'
import ProfileServices from '@/modules/profile/services/profile.services'
export default {
  // metaInfo () {
  //   return {
  //     meta: [
  //       {
  //         property: 'og:title',
  //         content: this.productDetails.name
  //       },
  //       {
  //         property: 'og:type',
  //         content: 'article'
  //       },
  //       {
  //         property: 'og:description',
  //         content: this.productDetails.description
  //       },
  //       {
  //         property: 'og:image',
  //         content: this.productDetails.image.length > 0 ? this.productDetails.image[0] : ''
  //       }
  //     ]
  //   }
  // },
  data () {
    return {
      fav: false,
      productDetails: [],
      maxQuantity: null,
      newPrice: '',
      orderDetails: {
        productId: '',
        size: '',
        color: null,
        quantity: 0
      },
      chooseColors: [],
      products: [
        {
          image: '@/assets/images/curva/product.png',
          name: 'Borussia Dortmund 2020/21 Fourth Jersey',
          price: 200,
          discount: 0,
          fav: false,
          offer: '',
          arrival: ''
        }
      ],
      /*       tableHeader: [
      /* tableHeader: [
        { title: 'Product name', key: 'productName', class: 'px-3 text-center' },
        { title: 'Select Size', key: 'selectSize', class: 'px-3 text-center min_120' },
        { title: 'Select Color', key: 'selectColor', class: 'px-3 text-center' },
        { title: 'Offer Package Quantity', key: 'OfferPackageQuantity', class: 'px-3 text-center', rowspan: 2 }
      ], */
      offerData: [
        {
          id: 1,
          name: 'Borussia Dortmund 2020/21 Fourth Jersey',
          image: require('@/assets/images/curva/product.png'),
          size: 34,
          unitPrice: 300,
          quantity: 2,
          subtotal: 300
        },
        {
          id: 1,
          name: 'Borussia Dortmund 2020/21 Fourth Jersey',
          image: require('@/assets/images/curva/product.png'),
          size: '',
          unitPrice: 300,
          quantity: 2,
          subtotal: 300
        }
      ],
      ifUserLogin: localStorage.getItem('userToken') ? localStorage.getItem('userToken') : false
    }
  },
  methods: {
    addToCart (id) {
      if (localStorage.getItem('userToken')) {
        ProductsServices.addToCart(id, {
          barcode: this.orderDetails.color,
          quantity: this.orderDetails.quantity
        }).then(res => {
          this.$store.dispatch('getCartContent')
          core.showSnackbar('success', this.$t('cart.ProductAddedToCartSuccessfully'))
          // this.$router.push({ name: 'cart' })
        })
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    addFav () {
      if (localStorage.getItem('userToken')) {
        if (!this.fav) {
          ProfileServices.addToWishlist(this.productDetails.id).then(res => {
            this.fav = true
          })
        } else {
          ProfileServices.removeFromWishlist(this.productDetails.id).then(res => {
            this.fav = false
          })
        }
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    getProductDetails () {
      ProductsServices.getProductDetails(this.$route.params.id).then(res => {
        this.productDetails = res.data.data
        /* for (const item in this.productDetails.avaiability) {
          this.productDetails.avaiability[item].sizeID = this.productDetails.avaiability[item].sizeID.trim().replace(/\D/g, '')
        } */
        const temp = [...new Map(this.productDetails.avaiability.map((item) => [item.sizeID, item])).values()]
        this.productDetails.size = temp.sort((a, b) => (a.size.size > b.size.size) ? 1 : ((b.size.size > a.size.size) ? -1 : 0))
        this.orderDetails.size = this.productDetails.size[0].size
        this.orderDetails.color = this.orderDetails.size.color
        this.orderDetails.productId = this.productDetails.id
      })
    }
  },
  computed: {
    calcPrice () {
      if (this.newPrice) {
        return Math.ceil(this.newPrice - ((this.productDetails.offer * this.newPrice) / 100))
      } else {
        return Math.ceil(this.productDetails.price - ((this.productDetails.offer * this.productDetails.price) / 100))
      }
    }
  },
  mounted () {
    this.getProductDetails()
    core.index()
  }
}
</script>
